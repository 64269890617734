import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, Container, CssBaseline } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { inject, observer } from 'mobx-react';
import logo from '@app/assets/logo.png';
import Loader from '@components/Loader';
import SettingsIcon from '@material-ui/icons/Settings';
import { history } from '@app/history';
import routes from '@routes';
import { PageContainer } from '@styles/globalStyles';

const useStyles = makeStyles(() => ({
  paper: {
    boxShadow: '0px 1px 13px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'white',
    padding: '30px 30px 0px 30px',
    borderRadius: '10px',
    color: '#808080',
  },
  subtitle: {
    marginTop: 10,
    fontSize: 20,
  },
  link: {
    width: 150,
    margin: 'auto',
    fontSize: 18,
    display: 'flex',
    color: '#24255f',
    textTransform: 'capitalize',
  },
  linkContainer: {
    border: '#24255f solid 1px',
    padding: 8,
    borderRadius: 10,
    justifyContent: 'space-between',
    width: 310,
    marginTop: 20,
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  },
  icon: {
    margin: 'auto',
    color: '#24255f',
  },
  avatar: {
    backgroundColor: '#24255f',
    color: 'white',
  },
  logout: {
    margin: 30,
    backgroundColor: '#24255f',
    color: 'white',
  },
}));

const Services = ({
  authStore: { logout, fetchAvailableServices, availableServices, isLoading },
}) => {
  const classes = useStyles();

  useEffect(async () => {
    await fetchAvailableServices();
  }, []);

  const goTo = {
    popcorn: () => {
      window.location.href = process.env.REACT_APP_POPCORN_URL;
    },
    popcorn_legacy: () => {
      window.location.href = process.env.REACT_APP_POPCORN_LEGACY_URL;
    },
    tool: () => {
      window.location.href = process.env.REACT_APP_TOOL_URL;
    },
    terra_control: () => {
      window.location.href = process.env.REACT_APP_TERRA_CONTROL_URL;
    },
    preferences: () => history.push(routes.preferences),
  };

  return (
    <PageContainer>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} alt="BriefcaseSSO" style={{ height: 160 }} />
          <div className={classes.subtitle}>Services</div>
          {isLoading && <Loader />}
          {availableServices.length === 0 && !isLoading && (
            <div className={classes.subtitle}>No available services</div>
          )}
          {availableServices.length > 0 &&
            availableServices.map(service => (
              <div
                role="button"
                tabIndex="0"
                onKeyDown={() => {}}
                onClick={goTo[service]}
                className={classes.linkContainer}
              >
                <Avatar className={classes.avatar}>
                  {service.charAt(0).toUpperCase()}
                </Avatar>
                <div className={classes.link}>{service.replace('_', ' ')}</div>
                <ArrowForwardIcon className={classes.icon} />
              </div>
            ))}
          <div
            role="button"
            tabIndex="0"
            onKeyDown={() => {}}
            onClick={goTo.preferences}
            className={classes.linkContainer}
          >
            <Avatar className={classes.avatar}>
              <SettingsIcon />
            </Avatar>
            <div className={classes.link}>Preferences</div>
            <ArrowForwardIcon className={classes.icon} />
          </div>
          <Button
            onClick={() => logout()}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.logout}
          >
            Sign out
          </Button>
        </div>
      </Container>
    </PageContainer>
  );
};

export default inject('authStore')(observer(Services));
